<template>
   <!-- 百度地图  -->
  <div id="bai-du-map">
    <!-- 技术支持和联系方式  -->
    
  </div>
</template>

<script>
import AMapLoader from "@amap/amap-jsapi-loader";
window._AMapSecurityConfig = {
    // 设置安全密钥
    securityJsCode: '94a63325e6cbc7e76ec870163e6b9461',
}
export default {
	name:'gMap',
    data() {
        return {
            map : null 
        }
    },
    methods : {
        initMap() {
            AMapLoader.load({
                "key": "8479bdb3a3f217a799193e7a6f3b1c9f", // 申请好的Web端开发者Key，首次调用 load 时必填
                "version": "2.0",   // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
                "plugins": ["AMap.Scale"],     
            }).then((AMap)=>{
                this.map = new AMap.Map('bai-du-map',{
                    viewMode : "2D",  //  是否为3D地图模式
                    zoom : 16,   // 初始化地图级别
                    center : [112.898735,28.19778],
                });
				new AMap.Marker({
				  map:this.map,
				  position : [112.898735,28.19778],
				  color:'#fff'
				})
       });
      } 
    },
    created() {
        this.initMap();
    }
	
};
</script>
<style scoped>
#bai-du-map {
    overflow: hidden;
    width: 100%;
    height: 100%;
    margin: 0;
    font-family: "微软雅黑";
}
</style>
<style>
/* 隐藏高德logo  */
.amap-logo {
    display: none !important;
}
/* 隐藏高德版权  */
.amap-copyright {
    display: none !important;
}
</style>
